body, html {
  background-color: #282c34;
  font-family: 'Courier New', Courier, monospace;
  color: #fff;
}

html {
  padding: 0;
  margin: 0;
}

body {
  padding: 0;
  margin: 0;
}

.App {
  text-align: center;
  background-image: url(https://media4.giphy.com/media/iOz3p2txHIo4U/giphy.gif?cid=ecf05e47wt4gidv61vigbt2dv61iwn6ssrke9khr0mgh1rxb&rid=giphy.gif&ct=g);
  background-size: cover;
  background-position: center center;
  height: 100vh;
}

.App-logo {
  height: 30vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 10s linear;
  }
}

.App-header {
  margin: 20px auto;
  background:rgba(0, 0, 0, 0.6);
  font-size: 18px;
}

.App-link {
  color: #61dafb;
}

p {
  padding: 5px;
  margin: 5px;
}

.pl-wrapper {
  max-width: 60%;
  margin: 0 auto;
}

.pl-wrapper > div {
  border: 1px solid rgba(0, 0, 0, 0.8);
  border-radius: 20px;
  background: rgba(0,0,0,.5);
  padding: 20px;
  font-size: 30px;
  text-align: center;
  font-weight: bold;
}

.pl-wrapper> div:hover {
  background: 
   linear-gradient(rgba(255,0,0,0.5) 0%, rgba(255,154,0,0.5) 10%, rgba(208,222,33,0.5) 20%, rgba(79,220,74,0.5) 30%, rgba(63,218,216,0.5) 40%, rgba(47,201,226,1) 50%, rgba(28,127,238,1) 60%, rgba(95,21,242,1) 70%, rgba(186,12,248,1) 80%, rgba(251,7,217,1) 90%, rgba(255,0,0,1) 100%) 
   0 0/100% 200%;
   animation: a 2s linear infinite;
  font-family: 'Comic Sans MS', cursive, sans-serif;
  cursor: pointer;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@media(max-width: 1200px) {
  .pl-4col-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}